<template>
    <div class='active-inactive-patients-container'>
        <div class='active-inactive-chart'>
            <v-chart class="chart" :option="getOption()" autoresize />
        </div>
        <div class="active-inactive-labels-container">
            <div class="label-container">
                <div class="icon-container">
                    <div class="icon" style=" height: 1.5rem;
            width: 1.5rem;
            background-color: #4CBC9A;
            border-radius: 2rem">
                    </div>
                </div>
                <div class="label" style=" font-weight: 600;">Active Patients</div>
            </div>
            <div class="label-container">
                <div class="icon-container">
                    <div class="icon" style="height: 1.5rem;width: 1.5rem;background-color: #D8DBDD;border-radius: 2rem;">
                    </div>
                </div>
                <div style="font-weight: 600;">In-active Patients</div>
            </div>

        </div>
    </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from 'echarts/components';
import dayjs from '@/util/dayjs';
import VChart, { THEME_KEY } from 'vue-echarts';
import * as echarts from 'echarts';
import { clients } from '@/util/apiRequests';
use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
]);
export default {
    name: 'PatientCountChart',
    components: {
        VChart,
    },
    data() {
        return {
            activeClients: 100,
            inactiveClients: 100
        }
    },
    props: {
    },
    methods: {


        getOption() {
            let myColors = ["#4CBC9A", "#D8DBDD"];
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                },
                series: [
                    {
                        name: 'Assigned Patients',
                        type: 'pie',
                        radius: ['20%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                            formatter: () => { return "" }
                        },
                        emphasis: {
                            scale: true,
                            scaleSize: 20,
                            label: {
                                show: false
                            },
                        },
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: (item) => {
                                return myColors[item.dataIndex]
                            },
                        },
                        data: [
                            { value: this.activeClients, name: 'Active' },
                            { value: this.inactiveClients, name: 'Inactive' },
                        ]
                    }
                ]
            };
            return option

        },

    },
    async mounted() {
        try {
            const res = await this.$api.get(clients.getClientsCount())
            if (res.status === 200) {
                const { activeClients, inactiveClients } = res.data.data
                this.activeClients = activeClients
                this.inactiveClients = inactiveClients
            }
        }
        catch (err) {
            this.$toasted.error('Failed to fetch assigned patients info!')
        }
    }
}
</script>
<style>
.active-inactive-patients-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.active-inactive-chart {
    width: 50%;
    height: 100%;
}

.active-inactive-labels-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.active-inactive-labels-container>.label-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem
}

/* .active-inactive-labels-conatiner>.label-container>.icon-container>.icon {}

.active-inactive-labels-conatiner>.label-container>.label {

   
} */
</style>