<template>
    <NoButtonModal :open="open" @close="$emit('cancel')">
      <button class="close-corner-x" type="button" @click.prevent="$emit('cancel')">
        <span class="material-icons-outlined dark-text">close</span>
      </button>
      <h1>Delete Alert?</h1>
      <p>Are you sure you want to delete this reminder ?</p>
      <button @click="$emit('cancel')" class="button secondary">Cancel</button>
      <button @click="$emit('handleDelete')" class="button primary">Delete</button>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    export default {
        name: 'DeleteReminderAlert',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
        }
    };

</script>
