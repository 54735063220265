<template>
    <div>
        <!-- <ejs-datepicker placeholder="Select a date" @change="(args) => adjustCurrentDate(args)"></ejs-datepicker> -->
        <div class="flex justify-center center bottom-10 agenda-nav">
            <span class="nav-arrow material-icons-outlined" @click="moveDate('backward')">arrow_back</span>
            <div class="left-5 right-5 dark-text font-16 weight-600">
                {{
                    `${convertToDayString(dayjs(currentDate).day())}, ${convertToMonthString(
                        dayjs(currentDate).month()
                    )} ${dayjs(currentDate).format('DD')}, ${dayjs(currentDate).format('YYYY')}`
                }}
            </div>
            <span class="nav-arrow material-icons-outlined" @click="moveDate('forward')">arrow_forward</span>
        </div>

        <div class="">
            <div class="flex flex-1">
                <div class="flex right-10 dark-text weight-600">
                    <div class="">{{ dayjs(currentDate).format('DD') }}&nbsp;</div>
                    <div> {{ convertToDayString(dayjs(currentDate).day()) }}</div>
                </div>
                <div class="flex-1 appts-scroll-column">
                    <div v-if="appointments.length > 0">
                        <div v-for="appt in appointments" :key="appt.id" @click="goToAppointment(appt)" class="flex bottom-5">
                            <div :style="`background-color:${appt.styles.bg}; color: ${appt.styles.text}`" class="dash-agenda-apt">
                                <div><b class="capitalize">{{ appt.appointment }}</b></div>
                                <div>
                                    {{ dayjs(appt.dayt_appt_start).format('h:mma') }} -
                                    {{ dayjs(appt.dayt_appt_end).format('h:mma') }}.
                                    {{ appointmentType(appt.appt_type) }}
                                </div>
                                
                                <div v-if="appt.counselors">
                                    <div v-if="appt.counselors.length > 2">
                                        <div>{{ appt.counselors.map((e) => e.name).join(', ') }}</div>
                                    </div>
                                    <div v-if="appt.counselors.length > 0 && appt.counselors.length < 2">
                                        <div>
                                            {{ appt.counselors[0].name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="no-apts" v-else>
                        No appointments today.
                    </div>
                </div>
            </div>
        </div>
        <!-- <schedule :isAgenda="true"/> -->
    </div>
</template>

<script>
    import Schedule from './Schedule.vue';
    import { appts } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import { convertToDayString, convertToMonthString } from '@/util/dayjs';

    export default {
        components: { Schedule },
        data() {
            return {
                convertToDayString,
                convertToMonthString,
                dayjs,
                appointments: [],
                currentDate: new Date(new Date().setHours(0, 0, 0, 0)),
                workDayStartHour: 6,
                workDayEndHour: 21
            };
        },
        methods: {
            appointmentType(type) {
                let t = type;
                switch (type) {
                    case 'Individual':
                        t = 'Individual Session';
                        break;
                    case 'Group':
                        t = 'Group Session';
                }
                return t;
            },
            async moveDate(direction) {
                let d = this.currentDate;
                if (direction == 'forward') {
                    d = dayjs(this.currentDate).add(1, 'days');
                }
                if (direction == 'backward') {
                    d = this.currentDate = dayjs(this.currentDate).subtract(1, 'days');
                }
                await this.adjustCurrentDate(d.toDate());
            },
            async adjustCurrentDate(args) {
                if (args) {
                    this.currentDate = args;
                } else {
                    this.currentDate = new Date(new Date().setHours(0, 0, 0, 0));
                }
                await this.getAppointments();
            },
            async getAppointments() {
                try {
                    let res = await this.$api.get(appts.getAll(), {
                        params: {
                            u: this.$store.state.user.id,
                            t: [0, 1],
                            isAgenda: true,
                            s: dayjs(this.currentDate).format('YYYY-MM-DDTHH:mm:ssZ'),
                            e: dayjs(this.currentDate).format('YYYY-MM-DDTHH:mm:ssZ')
                        }
                    });
                    this.appointments = Array.isArray(res.data) ? res.data : [];
                    if (this.appointments.length > 0) {
                        let currDate = dayjs(this.currentDate).format('MM/DD/YY');
                        //Filter through anything that is not current date
                        this.appointments = this.appointments.filter((element) => {
                            let d = dayjs(element.dayt_appt_start).format('MM/DD/YY');
                            if (dayjs(currDate).isSame(d)) {
                                return true;
                            }
                            return false;
                        });
                        //Filter anything not between work hours
                        this.appointments = this.appointments
                            .filter((element) => {
                                let start = dayjs(this.currentDate).add(this.workDayStartHour, 'hours');
                                //is between won't get appointments on workDayStartHour unless we do this.
                                start = start.subtract('1', 'second').format('MM/DD/YY HH:mm');
                                let end = dayjs(this.currentDate)
                                    .add(this.workDayEndHour, 'hours')
                                    .format('MM/DD/YY HH:mm');
                                let d = dayjs(element.dayt_appt_start).format('MM/DD/YY HH:mm');
                                if (dayjs(d).isBetween(start, end, 'hour')) {
                                    return true;
                                }
                                return false;
                            })
                            .map((element) => {
                                let styles = this.getAppointmentStyles(element.user_color);
                                element.styles = styles;
                                return element;
                            })
                            .sort((a, b) => (dayjs(a.dayt_appt_start).isAfter(dayjs(b.dayt_appt_start)) ? 1 : -1));
                    }
                } catch (error) {
                    //
                    console.log(error);
                }
            },
            async init() {
                await this.getAppointments();
            },
            getAppointmentStyles: function(args) {
                let color = args ? args : '#447ef2';
                let textColor = this.getTextColorForBackground(color);
                return { bg: color, text: textColor };
            },
            getTextColorForBackground(backgroundColor) {
                if (!/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(backgroundColor)) {
                    return '#FFFFFF';
                }
                const r = parseInt(`0x${backgroundColor[1]}${backgroundColor[2]}`);
                const g = parseInt(`0x${backgroundColor[3]}${backgroundColor[4]}`);
                const b = parseInt(`0x${backgroundColor[5]}${backgroundColor[6]}`);

                const luminance = r * 0.299 + g * 0.587 + b * 0.114;

                return luminance > 186 ? '#000000' : '#FFFFFF';
            },
            goToAppointment(appointment) {
                this.$router.push({
                    name: 'Calendar',
                    query: {
                        startTime: appointment.dayt_appt_start,
                        apptId: appointment.appt_id
                    }
                });
            }
        },
        async created() {
            this.init();
        }
    };
</script>