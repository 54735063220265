var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show_alert == '1')?_c('div',{staticClass:"m-1 rounded p-1 text-start",staticStyle:{"border":"1px solid #3D2BB1"}},[(1 || _vm.num_alerts)?_c('router-link',{staticClass:"alerts_details",attrs:{"to":{
            name: 'Alerts',
            params: {
                overrideFilter: {
                    sort: {},
                    search: {},
                    filter: { alert_type: [_vm.alert_type] },
                    page: { num_per_page: 10, current_page: 1, page_num: 1 },
                    column: []
                },
                show_all_alerts:this.all_alerts
            }
        }}},[_c('div',{class:_vm.classes,style:(_vm.alertMessageStyle)},[_c('span',[_vm._v(_vm._s(_vm.ucwords(_vm.alert_type)))]),_c('div',{staticClass:"num-alerts"},[_vm._v(_vm._s(_vm.num_alerts))])])]):_c('div',{staticClass:"alerts_details"},[_c('div',{class:_vm.classes,style:(_vm.alertMessageStyle)},[_c('span',[_vm._v(_vm._s(_vm.ucwords(_vm.alert_type)))]),_c('div',{staticClass:"num-alerts"},[_vm._v(_vm._s(_vm.num_alerts))])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }